import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/layout";
import { LogoSpiric } from "../components/layout/logo_spiric";

export interface IDogodkiProps {}

export function Dogodki(props: IDogodkiProps) {
  return (
    <React.Fragment>
      <Helmet title="Dogodki | Gostilna Spirić" defer={false} />
      <article className="container flex flex-col px-4 mx-auto mt-8 gap-9">
        <div className="grid">
          <LogoSpiric className="col-start-1 row-start-1 self-start justify-self-center max-w-[800px] text-gray-500 opacity-50 fill-current " />
          <div className="col-start-1 row-start-1 justify-self-center">
            <h1 className="text-4xl">Tu pride galerija</h1>
            <div>KOZEL FEST</div>
            <div>BOGRAČIJADA</div>
          </div>
        </div>
      </article>
    </React.Fragment>
  );
}

export default Dogodki;
